import axios from "axios";
import { setAlert } from "./alert";

// Send Form1 (Besuchsbericht)
export const sendForm1 = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    await axios.post("/api/forms/send1", formData, config);

    dispatch(
      setAlert(
        "Besprechungs- / Anrufnotiz versendet an: " + formData.recipient,
        "success"
      )
    );

    history.push("/form1");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
