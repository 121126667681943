import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import AddedSalesDocuments from "./AddedSalesDocuments";
import SendAddedSalesDocuments from "./SendAddedSalesDocuments";

const SendSalesDocuments = ({
  auth: { user },
  document: { addedSalesDocuments, loading }
}) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  return addedSalesDocuments === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <section className="page-section">
        <div className="container">
          <h2 className="page-section-heading text-uppercase text-primary mb-0">
            Versand
          </h2>
          <br />
          <header className="masthead text-primary" id="versand-bg"></header>
          <br />
          <Fragment>
            <AddedSalesDocuments salesdocuments={addedSalesDocuments} />
            <SendAddedSalesDocuments salesdocuments={addedSalesDocuments} />
          </Fragment>
        </div>
      </section>
    </Fragment>
  );
};

SendSalesDocuments.propTypes = {
  auth: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  document: state.document
});

export default connect(mapStateToProps, {})(SendSalesDocuments);
