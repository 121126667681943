import { connect } from 'react-redux';
import PropTypes from "prop-types";

const CheckPermissions = ({
  admin, children}) => {
  if(admin){
    return(
      children
    )
  }else{
    return null;
  }
}

CheckPermissions.propTypes = {
  admin: PropTypes.bool.isRequired,
};

function adminFromState(state){
  return state.auth.user ? state.auth.user.admin : false;
}

const mapStateToProps = (state) => ({
  admin: adminFromState(state)
});

export default connect(mapStateToProps)(CheckPermissions)