import {
  GET_UPDATED_SALESDOCUMENTS,
  UPDATED_SALESDOCUMENT_ERROR,
  CLEAR_UPDATED_SALESDOCUMENTS,
  GET_SALESDOCUMENTS,
  SALESDOCUMENT_ERROR,
  CLEAR_SALESDOCUMENTS,
  CLEAR_ADDED_SALESDOCUMENTS,
  ADD_SALESDOCUMENT,
  REMOVE_SALESDOCUMENT,
  DELETE_SALESDOCUMENT
} from "../actions/types";

const initialState = {
  updatedDocuments: [],
  salesDocuments: [],
  addedSalesDocuments: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_UPDATED_SALESDOCUMENTS:
      return {
        ...state,
        updatedDocuments: payload,
        loading: false
      };
    case UPDATED_SALESDOCUMENT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_UPDATED_SALESDOCUMENTS:
      return {
        ...state,
        updatedDocuments: [],
        loading: false
      };
    case GET_SALESDOCUMENTS:
      return {
        ...state,
        salesDocuments: payload,
        loading: false
      };
    case SALESDOCUMENT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_SALESDOCUMENTS:
      return {
        ...state,
        salesDocuments: [],
        loading: false
      };
    case ADD_SALESDOCUMENT:
      return {
        ...state,
        addedSalesDocuments: [...state.addedSalesDocuments, payload]
      };
    case REMOVE_SALESDOCUMENT:
      return {
        ...state,
        addedSalesDocuments: [
          ...state.addedSalesDocuments.filter(
            addedSalesDocuments => addedSalesDocuments._id !== payload
          )
        ]
      };
    case DELETE_SALESDOCUMENT:
      return {
        ...state,
        addedSalesDocuments: [
          ...state.addedSalesDocuments.filter(
              addedSalesDocuments => addedSalesDocuments._id !== payload
          )
        ],
        salesDocuments: [
          ...state.salesDocuments.filter(
              salesDocument => salesDocument._id !== payload
          )
        ]
      };
    case CLEAR_ADDED_SALESDOCUMENTS:
      return {
        ...state,
        addedSalesDocuments: [],
        loading: false
      };

    default:
      return state;
  }
}
