export const getCategoryName = category_id => {
  var categoryName = "";

  switch (category_id) {
    case "1":
      categoryName = "Dienstleistungen";
      break;
    case "2":
      categoryName = "Kundenschreiben";
      break;
    case "3":
      categoryName = "Behälter & Fahrzeuge";
      break;
    case "4":
      categoryName = "Trennanleitungen";
      break;
    case "5":
      categoryName = "Unternehmen";
      break;
    case "6":
      categoryName = "Kundenmagazine";
      break;
    case "7":
      categoryName = "Infoblätter & -folder";
      break;
    case "8":
      categoryName = "Formulare";
      break;
    case "9":
      categoryName = "Produktdatenblätter";
      break;
    case "10":
      categoryName = "Intern";
      break;
    case "11":
      categoryName = "Wertstoffkreisläufe";
      break;
    default:
      categoryName = "Keine Kategorie";
      break;
  }
  return categoryName;
};
