import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import Alert from "../../components/layout/Alert";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const { email, password } = formData;

  const onChange = async e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment>
      <section className="page-section">
        <div className="container">
          <br />
          <p className="text-center lead">
            <i className="fas fa-user"></i> Login
          </p>
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <form className="form" onSubmit={e => onSubmit(e)}>
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Email Address</label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      placeholder="E-Mail-Adresse (@saubermacher.at)"
                      name="email"
                      value={email}
                      onChange={e => onChange(e)}
                      required
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Passwort</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Passwort"
                      name="password"
                      value={password}
                      onChange={e => onChange(e)}
                      minLength="4"
                      required
                    />
                  </div>
                </div>

                <br />
                <Alert />
                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-xl"
                    id="sendMessageButton"
                  >
                    Login
                  </button>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);
