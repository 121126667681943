import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import document from "./document";
import users from "./users";

export default combineReducers({
  alert,
  auth,
  document,
  users
});
