import {
    GET_USERLIST,
    USERLIST_ERROR,
    DELETE_USER,
    DELETE_USER_ERROR,
    GET_USER,
    GET_USER_ERROR,
    CLEAR_GET_USER,
    CLEAR_USERLIST
} from "../actions/types";

const initialState = {
    userlist: [],
    loading: true,
    error: {}
}

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case GET_USERLIST:
            return {
                ...state,
                userlist: payload,
                loading: false
            };
        case GET_USER:
            return {
                ...state,
                user: payload,
                loading: false
            };
        case GET_USER_ERROR:
        case USERLIST_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        case CLEAR_USERLIST:
            return {
                ...state,
                userlist: [],
                loading: false
            };
        case CLEAR_GET_USER:
            return {
                ...state,
                user: [],
                loading: false
            };
        case DELETE_USER:
            return {
                ...state,
                userlist: [
                    ...state.userlist.filter(
                        user => user._id !== payload
                    )
                ],
                loading: false
            };
        case DELETE_USER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
