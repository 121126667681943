import React, { useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import Alert from "../../components/layout/Alert";
import CheckPermissions from './CheckPermissions';

const Register = ({ setAlert, register }) => {
  const initialState = {
    name: "",
    email: "",
    position: "",
    telephone: "",
    password: "",
    password2: "",
    sendLoginAsEMail: ""
  };
  const [formData, setFormData] = useState(initialState);

  const {
    name,
    email,
    position,
    telephone,
    password,
    password2,
    sendLoginAsEMail
  } = formData;

  const onChange = async e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwörter stimmen nicht überein", "danger");
    } else {
      register({
        name,
        email,
        position,
        telephone,
        password,
        sendLoginAsEMail
      }).then(setFormData(initialState));
    }
  };


  return (
    <CheckPermissions>
      <section className="page-section">
        <div className="container">
          <br />
          <p className="text-center lead">
            <i className="fas fa-user"/> Registrierung
          </p>
          <p className="text-center">
            <small>* = Pflichtfelder</small>
          </p>
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <form className="form" onSubmit={e => onSubmit(e)}>
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="* Name"
                      name="name"
                      value={name}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                </div>{" "}
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Position</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="* Position"
                      name="position"
                      value={position}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                </div>{" "}
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Telefonnr.</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="* Telefonnr. (+43...)"
                      name="telephone"
                      value={telephone}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                </div>{" "}
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>E-Mail Adresse</label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="* E-Mail Adresse"
                      name="email"
                      value={email}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                </div>{" "}
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Passwort</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="* Passwort"
                      name="password"
                      value={password}
                      onChange={e => onChange(e)}
                      minLength="4"
                    />
                  </div>
                </div>{" "}
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Passwort wiederholen</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="* Passwort wiederholen"
                      name="password2"
                      value={password2}
                      onChange={e => onChange(e)}
                      minLength="4"
                    />
                  </div>
                </div>{" "}
                <div className="control-group">
                  <br />
                  <div className="form-group mb-0 pb-2">
                    <select
                      className="form-control"
                      name="sendLoginAsEMail"
                      value={sendLoginAsEMail}
                      onChange={e => onChange(e)}
                    >
                      <option value="0" className="form-control">
                        * Zugangsdaten versenden?
                      </option>
                      <option value="yes" className="form-control">
                        Ja
                      </option>
                      <option value="no" className="form-control">
                        Nein
                      </option>
                    </select>
                    <small className="form-text">
                      * Zugangsdaten an die oben angeführte E-Mail-Adresse
                      senden?
                    </small>
                  </div>
                </div>
                <br />
                <Alert />
                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-xl"
                    id="sendMessageButton"
                  >
                    Neuen Benutzer registrieren
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </CheckPermissions>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

export default connect(null, { setAlert, register })(Register);
