import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {setAlert} from "../../actions/alert";
import {editUser} from "../../actions/auth";
import PropTypes from "prop-types";
import Alert from "../../components/layout/Alert";
import CheckPermissions from './CheckPermissions';
import {getUserById} from "../../actions/users";
import Spinner from "../layout/Spinner";
import moment from "moment";
import 'moment/locale/de';

const EditUser = ({
                      match,
                      users: {user, loading},
                      getUserById,
                      editUser
                  }) => {

    const userId = match.params.userId;

    useEffect(() => {
        getUserById(userId);

        if (user) {
            setFormData({
                name: loading || !user.name ? "" : user.name,
                email: loading || !user.email ? "" : user.email,
                position: loading || !user.position ? "" : user.position,
                telephone: loading || !user.telephone ? "" : user.telephone,
                password: loading || "",
                sendLoginAsEMail: loading || "no",
                date: loading || !user.date ? "" : moment(user.date).locale('de').format('LLL'),
            })
        }
    }, [loading, userId, getUserById]);

    const initialState = {
        name: "",
        email: "",
        position: "",
        telephone: "",
        password: "",
        password2: "",
        sendLoginAsEMail: "",
        date: ""
    };

    const [formData, setFormData] = useState(initialState);

    const {
        name,
        email,
        position,
        telephone,
        password,
        sendLoginAsEMail,
        date
    } = formData;

    const onChange = async e => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const onSubmit = async e => {
        e.preventDefault();

        editUser({
            user,
            userId,
            name,
            position,
            telephone,
            email,
            password,
            sendLoginAsEMail
        });
        getUserById(userId);
    };

    return user === null || user === undefined ? (
        <Spinner/>
    ) : (
        <CheckPermissions>
            <section className="page-section">
                <div className="container">
                    <br/>
                    <p className="text-center lead">
                        <i className="fas fa-user"/> Benutzer bearbeiten
                    </p>
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <form className="form" onSubmit={e => onSubmit(e)}>
                                <div className="control-group">
                                    <div className="form-group floating-label-form-group controls mb-0 pb-2">
                                        <label>Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="* Name"
                                            name="name"
                                            value={name}
                                            onChange={e => onChange(e)}
                                            required
                                        />
                                    </div>
                                </div>
                                {" "}
                                <div className="control-group">
                                    <div className="form-group floating-label-form-group controls mb-0 pb-2">
                                        <label>Position</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="* Position"
                                            name="position"
                                            value={position}
                                            onChange={e => onChange(e)}
                                            required
                                        />
                                    </div>
                                </div>
                                {" "}
                                <div className="control-group">
                                    <div className="form-group floating-label-form-group controls mb-0 pb-2">
                                        <label>Telefonnr.</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="* Telefonnr. (+43...)"
                                            name="telephone"
                                            value={telephone}
                                            onChange={e => onChange(e)}
                                            required
                                        />
                                    </div>
                                </div>
                                {" "}
                                <div className="control-group">
                                    <div className="form-group floating-label-form-group controls mb-0 pb-2">
                                        <label>E-Mail Adresse</label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            placeholder="* E-Mail Adresse"
                                            name="email"
                                            value={email}
                                            onChange={e => onChange(e)}
                                            required
                                        />
                                    </div>
                                </div>
                                {" "}
                                <div className="control-group">
                                    <div className="form-group floating-label-form-group controls mb-0 pb-2">
                                        <label>Passwort</label>
                                        <input
                                            className="form-control"
                                            type="password"
                                            placeholder="Passwort ändern"
                                            name="password"
                                            value={password}
                                            onChange={e => onChange(e)}
                                            minLength="4"
                                        />
                                    </div>
                                </div>
                                {" "}
                                <div className="control-group">
                                    <br/>
                                    <div className="form-group mb-0 pb-2">
                                        <select
                                            className="form-control"
                                            name="sendLoginAsEMail"
                                            value={sendLoginAsEMail}
                                            onChange={e => onChange(e)}>
                                            <option value="yes" className="form-control">
                                                Ja
                                            </option>
                                            <option value="no" className="form-control">
                                                Nein
                                            </option>
                                        </select>
                                        <small className="form-text">
                                            * Zugangsdaten an die oben angeführte E-Mail-Adresse
                                            senden?
                                        </small>
                                    </div>
                                </div>
                                <br/>
                                <Alert/>
                                <div className="form-group text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-xl"
                                        id="sendMessageButton">
                                        Änderungen speichern
                                    </button>
                                </div>
                                <br/>
                                <div className="form-group text-center">
                                    <small className="form-text">
                                        User wurde erstellt am:<br/>
                                        {date}
                                    </small>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </CheckPermissions>
    );
};

EditUser.propTypes = {
    setAlert: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    editUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    users: state.users
});

export default connect(mapStateToProps, {setAlert, editUser, getUserById})(EditUser);
