import React, {Fragment, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import CheckPermissions from '../auth/CheckPermissions';
import {deleteUser} from "../../actions/users";
import Alert from "../layout/Alert";
import AlertDialog from "../layout/AlertDialog";
import {PaginatedList} from "react-paginated-list";


const UserlistC = ({
                       userlist,
                       deleteUser
                   }) => {

    if (userlist && userlist !== null) {
        userlist = userlist.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
    }

    const [userFiltered, setUsers] = useState(userlist);
    const [search, setSearch] = React.useState("");

    useEffect(() => {
        setUsers(userlist);
    }, [userlist]);

    var userlistOutput = [];

    if (userFiltered.length === 0) {
        userlistOutput = userlist;
    } else {
        userlistOutput = userFiltered;
    }

    const displayeduserlist = userlistOutput.map((person, index) => {

        return (
                <li key={person._id} className="listplusdocuments">
                    {person.name},&nbsp;
                    {person.email}&nbsp;
                    <CheckPermissions>
                        <a href={"/edit-user/" + person._id}
                           title={"Daten von " + person.name + " bearbeiten"}
                           key={person._id}>
                            Bearbeiten
                        </a>
                        &nbsp;|&nbsp;
                        <AlertDialog
                            title={person.name +" löschen"}
                            text={"Löschen"}
                            header={"Benutzer löschen"}
                            message={"Soll der Benutzer "+ person.name +" wirklich gelöscht werden?"}
                            action={ () => deleteUser(person._id, person.name) } />
                    </CheckPermissions>
                </li>
        );
    });

    return (
        <Fragment>
            <div className="form">
                <input
                    className="form-control"
                    onChange={e => {
                        if (e.target.value !== "") {
                            const filteredUserlist = userlist.filter(user => {

                                return userlist.find(function () {
                                    return user.name.toLowerCase().includes(e.target.value.toLowerCase()) || user.email.toLowerCase().includes(e.target.value.toLowerCase());
                                });
                            });
                            setUsers(filteredUserlist);
                        } else {
                            setUsers(userlist);
                        }
                        setSearch(e.target.value);
                    }}
                    type="text"
                    placeholder="Suche"
                    value={search}
                />
                <br/>
                <p>Hier können die Daten unserer BenutzerInnen bearbeitet und gelöscht werden.</p>
            </div>
            <Alert/>
            <ul className="listplusdocuments">
                <b style={{display: "block", margin: "20px 0 10px -20px"}}>
                    Zugänge
                </b>
                <PaginatedList
                    list={displayeduserlist}
                    itemsPerPage={25}
                    renderList={(list) => (
                        <>
                            {list.map((item, key) => (
                                <span key={key}>{item}</span>
                            ))}
                        </>
                    )}
                />
            </ul>
        </Fragment>
    );
};

UserlistC.propTypes = {
    userlist: PropTypes.array.isRequired,
    deleteUser: PropTypes.func.isRequired,
}

export default connect(null, {deleteUser})(
    UserlistC
);
