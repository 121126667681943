import React from "react";
import { Link } from "react-router-dom";
import CheckPermissions from '../auth/CheckPermissions';

const Footer = () => {
  return (
    <div className="completeFooter">
      <footer className="footer text-center industry-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-5 mb-lg-0"/>

            <div className="col-lg-4 mb-5 mb-lg-0">
              <h4 className="text-uppercase mb-4">Saubermacher folgen</h4>
              <a
                className="text-white btn-social mx-1 noUnderline"
                href="https://saubermacher.at/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-globe-americas"/>
              </a>
              <a
                className="text-white btn-social mx-1"
                href="https://www.linkedin.com/company/saubermacher/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"/>
              </a>
              <a
                className="text-white btn-social mx-1"
                href="https://www.facebook.com/SaubermacherAG"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-square"/>
              </a>
     <a
                className="text-white btn-social mx-1"
                href="https://www.instagram.com/team.saubermacher/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"/>
              </a>
              <a
                className="text-white btn-social mx-1"
                href="https://www.youtube.com/user/saubermachervideo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube-square"/>
              </a>
              <a
                className="text-white btn-social mx-1"
                href="https://www.flickr.com/photos/saubermacher/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-fw fa-flickr"/>
              </a>
            </div>
            <div className="moveUpOnMobile">
              <br />
              <br />
              <br />
              &nbsp;
              <br />
              <br />
            </div>

            <div className="col-lg-4"/>
          </div>
        </div>
      </footer>

      <section className="copyright py-4 text-center text-primary">
        <div className="container">
          <small>
            &copy; {new Date().getFullYear()} Saubermacher Dienstleistungs AG |{" "}
            <Link to="/contact" className="noUnderline">
              Kontakt
            </Link>{" "}
            |{" "}
            <Link to="/links" className="noUnderline">
              Links
            </Link>{" "}
            |{" "}
            <a
              href="https://saubermacher.at/zum-newsletter-anmelden/?utm_source=signatur&utm_medium=email&utm_campaign=adressgener"
              className="noUnderline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Newsletter
            </a>
            <CheckPermissions>
              {" "}|{" "}
              <Link to="/register-new-user" className="noUnderline">
                Benutzer hinzufügen
              </Link>
              {" "}|{" "}
              <Link to="/show-all-users" className="noUnderline">
                Benutzer bearbeiten
              </Link>
              {" "}|{" "}
              <Link to="/upload-document" className="noUnderline">
                Dokumente hinzufügen
              </Link>
            </CheckPermissions>
          </small>
        </div>
      </section>

      <div className="scroll-to-top d-lg-none position-fixed ">
        <a
          className="js-scroll-trigger d-block text-center text-white rounded"
          href="#page-top"
        >
          <i className="fa fa-chevron-up"/>
        </a>
      </div>
    </div>
  );
};


export default Footer;
