import React from "react";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <div>
      <p className="lead">Unterlagen</p>
      <p>Wähle die jeweilige Kategorie aus, um die Unterlagen anzuzeigen:</p>
      <div className="row" style={{ margin: "-15px 0 0 0" }}>
        <div className="col-md-6 col-lg-3">
          <div
            className="portfolio-item mx-auto text-center"
            data-toggle="modal"
            data-target="#portfolioModal1"
          >
            <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
              <div className="portfolio-item-caption-content text-center text-white">
                <i className="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <Link to="/salesdocuments/1" className="categories">
              <img
                className="img-fluid"
                id="img-fluid1"
                src="/img/1.jpg"
                alt=""
              />
              Dienstleistungen
            </Link>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div
            className="portfolio-item mx-auto text-center"
            data-toggle="modal"
            data-target="#portfolioModal1"
          >
            <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
              <div className="portfolio-item-caption-content text-center text-white">
                <i className="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <Link to="/salesdocuments/2" className="categories">
              <img
                className="img-fluid"
                id="img-fluid2"
                src="/img/2.jpg"
                alt=""
              />
              Kundenschreiben
            </Link>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div
            className="portfolio-item mx-auto text-center"
            data-toggle="modal"
            data-target="#portfolioModal1"
          >
            <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
              <div className="portfolio-item-caption-content text-center text-white">
                <i className="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <Link to="/salesdocuments/3" className="categories">
              <img
                className="img-fluid"
                id="img-fluid3"
                src="/img/3.jpg"
                alt=""
              />
              Behälter &amp; Fahrzeuge
            </Link>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div
            className="portfolio-item mx-auto text-center"
            data-toggle="modal"
            data-target="#portfolioModal1"
          >
            <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
              <div className="portfolio-item-caption-content text-center text-white">
                <i className="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <Link to="/salesdocuments/4" className="categories">
              <img
                className="img-fluid"
                id="img-fluid4"
                src="/img/4.jpg"
                alt=""
              />
              Trennanleitungen
            </Link>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div
            className="portfolio-item mx-auto text-center"
            data-toggle="modal"
            data-target="#portfolioModal1"
          >
            <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
              <div className="portfolio-item-caption-content text-center text-white">
                <i className="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <Link to="/salesdocuments/7" className="categories">
              <img
                className="img-fluid"
                id="img-fluid7"
                src="/img/7.jpg"
                alt=""
              />
              Infoblätter &amp; -folder
            </Link>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div
            className="portfolio-item mx-auto text-center"
            data-toggle="modal"
            data-target="#portfolioModal1"
          >
            <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
              <div className="portfolio-item-caption-content text-center text-white">
                <i className="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <Link to="/salesdocuments/8" className="categories">
              <img
                className="img-fluid"
                id="img-fluid8"
                src="/img/8.jpg"
                alt=""
              />
              Formulare
            </Link>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div
            className="portfolio-item mx-auto text-center"
            data-toggle="modal"
            data-target="#portfolioModal1"
          >
            <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
              <div className="portfolio-item-caption-content text-center text-white">
                <i className="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <Link to="/salesdocuments/5" className="categories">
              <img
                className="img-fluid"
                id="img-fluid5"
                src="/img/5.jpg"
                alt=""
              />
              Unternehmen
            </Link>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div
            className="portfolio-item mx-auto text-center"
            data-toggle="modal"
            data-target="#portfolioModal1"
          >
            <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
              <div className="portfolio-item-caption-content text-center text-white">
                <i className="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <Link to="/salesdocuments/11" className="categories">
              <img
                className="img-fluid"
                id="img-fluid11"
                src="/img/11.jpg"
                alt=""
              />
              Wertstoffkreisläufe
            </Link>
          </div>
        </div>

      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Categories;
