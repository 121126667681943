import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addSalesDocument, removeSalesDocument, deleteSalesDocument } from "../../actions/document";
import CheckPermissions from '../auth/CheckPermissions';

const SalesDocumentsC = ({
  salesdocuments,
  addSalesDocument,
  removeSalesDocument,
  deleteSalesDocument
}) => {
  // Filter SalesDocuments
  const [salesdocs, setDocs] = useState(salesdocuments);
  const [search, setSearch] = React.useState("");

  useEffect(() => {
    setDocs(salesdocuments);
  }, [salesdocuments]);

  var salesDocOutput = [];

  if (salesdocs.length === 0) {
    salesDocOutput = salesdocuments;
  } else {
    salesDocOutput = salesdocs;
  }

  var section = "empty";
  var headline;

  const documents = salesDocOutput.map((doc, index) => {
    if (section === doc.section) {
      headline = false;
    } else {
      headline = true;
      section = doc.section;
    }
    return (
      <div key={doc._id}>
        {headline ? (
          <b style={{ display: "block", margin: "20px 0 10px -20px" }}>
            {doc.section}
          </b>
        ) : (
          ""
        )}
        <li key={doc._id} className="listplusdocuments">
          {doc.name}&nbsp;
          <a
            href={doc.url}
            target="_blank"
            title={doc.name + " anzeigen"}
            rel="noopener noreferrer"
            key={doc._id}
          >
            Anzeigen
          </a>
          &nbsp;|&nbsp;
          <a
            href="#!"
            title={doc.name + " zur Versandauswahl hinzufügen"}
            onClick={e => {
              addSalesDocument(doc._id, doc.name, doc.url);
            }}
          >
            Hinzufügen
          </a>
          &nbsp;|&nbsp;
          <a
            href="#!"
            title={doc.name + " von der Versandauswahl entfernen"}
            onClick={e => {
              removeSalesDocument(doc._id, doc.name);
            }}
          >
            Entfernen
          </a>
          <CheckPermissions>
              &nbsp;|&nbsp;
              <a
                href="#!"
                title={doc.name + " von dem Salesportal löschen"}
                onClick={e => {
                  deleteSalesDocument(doc._id, doc.name);
                }}
              >
                Dokument löschen (Admin)
              </a>
          </CheckPermissions>
        </li>
      </div>
    );
  });
  return (
    <Fragment>
      <div className="form">
        <input
          className="form-control"
          onChange={e => {
            if (e.target.value !== "") {
              const filteredSalesDocuments = salesdocuments.filter(doc => {
                return salesdocuments.find(name =>
                  doc.name.toLowerCase().includes(e.target.value.toLowerCase())
                );
              });

              setDocs(filteredSalesDocuments);
            } else {
              setDocs(salesdocuments);
            }
            setSearch(e.target.value);
          }}
          type="text"
          placeholder="Suche"
          value={search}
        />
        <br />
        <p>Wähle eine oder mehrere Unterlage(n) aus:</p>
      </div>
      <ul className="listplusdocuments">{documents}</ul>
    </Fragment>
  );
};

SalesDocumentsC.propTypes = {
  salesdocuments: PropTypes.array.isRequired,
  addSalesDocument: PropTypes.func.isRequired,
  removeSalesDocument: PropTypes.func.isRequired,
  deleteSalesDocument: PropTypes.func.isRequired,
};

export default connect(null, { addSalesDocument, removeSalesDocument, deleteSalesDocument })(
  SalesDocumentsC
);
