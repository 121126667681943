import axios from "axios";
import {setAlert} from "./alert";

import {
    GET_USERLIST,
    USERLIST_ERROR,
    DELETE_USER,
    DELETE_USER_ERROR,
    GET_USER,
    GET_USER_ERROR
} from "./types";

// Get all users
export const getUserlist = () => async dispatch => {
    try {
        const res = await axios.get("/api/users/");

        dispatch({
            type: GET_USERLIST,
            payload: res.data
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: USERLIST_ERROR,
            payload: {msg: err, status: err}
        });
    }
}

// Get user by id
export const getUserById = (userId) => async dispatch => {
    try {
        const res = await axios.get("/api/users/" + userId);

        dispatch({
            type: GET_USER,
            payload: res.data
        });
        return true;
    } catch (err) {
        dispatch({
            type: GET_USER_ERROR,
            payload: {msg: err, status: err}
        });
        return false;
    }
}

// Delete chosen user
export const deleteUser = (userId, userName) => async dispatch => {
    try {
        await axios.delete("/api/users/" + userId);

        dispatch({
            type: DELETE_USER,
            payload: userId,
        });
        dispatch(
            setAlert("Benutzer " + userName + " wurde gelöscht.", "success")
        );
    } catch (err) {
        dispatch({
            type: DELETE_USER_ERROR,
            payload: {msg: err, status: err}
        });
        dispatch(
            setAlert("Fehler beim Löschen des Benutzers " + userName, "danger")
        );
    }
}
