import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

const Navbar = ({
  auth: { isAuthenticated, loading, user },
  document: { addedSalesDocuments },
  logout
}) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  var numberOfAddedSalesDocuments = "";

  if (addedSalesDocuments.length > 0) {
    numberOfAddedSalesDocuments = addedSalesDocuments.length;
    numberOfAddedSalesDocuments = "(" + numberOfAddedSalesDocuments + ")";
  }

  const authNavLinks = (
    <ul className="navbar-nav ml-auto">
      <li className="nav-item mx-0 mx-lg-1">
        <NavLink to="/dashboard" className="nav-link js-scroll-trigger">
          Unterlagen
        </NavLink>
      </li>
      <li className="nav-item mx-0 mx-lg-1">
        <NavLink to="/send" className="nav-link js-scroll-trigger">
          Versand&nbsp;{numberOfAddedSalesDocuments}
        </NavLink>
      </li>
      <li className="nav-item mx-0 mx-lg-1">
        <NavLink to="/form1" className="nav-link js-scroll-trigger">
          Besprechungsnotiz
        </NavLink>
      </li>
      <li className="nav-item mx-0 mx-lg-1">
        <NavLink to="/salesdocuments/10" className="nav-link js-scroll-trigger">
          Intern
        </NavLink>
      </li>
      <li className="nav-item mx-0 mx-lg-1">
        <a onClick={logout} href="#!" className="nav-link js-scroll-trigger">
          <span>Logout</span> {/*user && user.name*/}
        </a>
      </li>
    </ul>
  );

  const guestNavLinks = <div></div>;
  const [displayToggleMenu, ToggleMenuForms] = useState(false);

  const showToggleMenu = (
    <div className="collapse navbar-collapse show" id="navbarResponsive">
      {!loading && (
        <Fragment>{isAuthenticated ? authNavLinks : guestNavLinks}</Fragment>
      )}
    </div>
  );

  const hideToggleMenu = (
    <div className="collapse navbar-collapse" id="navbarResponsive">
      {!loading && (
        <Fragment>{isAuthenticated ? authNavLinks : guestNavLinks}</Fragment>
      )}
    </div>
  );

  return (
    <nav
      className="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top"
      id="mainNav"
    >
      <div className="container">
        <NavLink to="/" className="navbar-brand js-scroll-trigger">
          <img
            src="/img/saubermacher_logo.png"
            alt="Home"
            title="Home"
            width="191"
            height="50"
          />
        </NavLink>
        <button
          className="navbar-toggler navbar-toggler-right btn btn-primary btn-xl"
          type="button"
          aria-label="Navigation ausfahren"
          onClick={() => ToggleMenuForms(!displayToggleMenu)}
        >
          <i className="fas fa-bars"></i>
        </button>
        {!loading && (
          <Fragment>
            {displayToggleMenu ? showToggleMenu : hideToggleMenu}
          </Fragment>
        )}
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  document: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  document: state.document
});

export default connect(mapStateToProps, { logout })(Navbar);
