import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  adminOnly,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if(!isAuthenticated && !loading) {
        return <Redirect to="/login" />;
      } else if(!permissionCorrect(adminOnly, user) && !loading) {
        return <Redirect to="/dashboard" />;
      } else
        return <Component {...props} />;
      }
    }
  />
);

function permissionCorrect(adminOnly, user){
  if (adminOnly) {
    return user ? user.admin : false;
  } else {
    return true;
  }
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  adminOnly: PropTypes.bool,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
