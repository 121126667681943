import React, { Fragment } from "react";
import PropTypes from "prop-types";

const UpdatedSalesDocuments = ({ newdocuments }) => {
  const documents = newdocuments.map(doc => (
    <li key={doc._id} className="text-primary listplus">
      <a
        href={doc.url}
        target="_blank"
        title={doc.name + " anzeigen"}
        rel="noopener noreferrer"
        key={doc._id}
        className="text-primary"
      >
        {doc.name}
      </a>
    </li>
  ));
  return (
    <Fragment>
      <div className="row" id="row-us">
        <div className="col-lg-6 ml-auto"></div>
        <div className="col-lg-6 mr-auto text-left updatedSalesDocuments">
          <p className="lead">Neue Unterlagen</p>
          <ul className="listplus">{documents}</ul>
        </div>
      </div>
    </Fragment>
  );
};

UpdatedSalesDocuments.propTypes = {
  newdocuments: PropTypes.array.isRequired
};

export default UpdatedSalesDocuments;
