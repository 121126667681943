import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./App.css";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Dashboard from "./components/dashboard/Dashboard";
import SalesDocuments from "./components/documents/SalesDocuments";
import SendSalesDocuments from "./components/documents/SendSalesDocuments";
import PrivateRoute from "./components/routing/PrivateRoute";
import Form1 from "./components/pages/Form1.js";
import Links from "./components/pages/Links.js";
import Contact from "./components/pages/Contact.js";
import Register from "./components/auth/Register";
import Userlist from "./components/auth/Userlist";
import EditUser from "./components/auth/EditUser";
import Login from "./components/auth/Login";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import UploadDocument from "./components/documents/UploadDocument";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Navbar />
          <section className="container">
            <Switch>
              <Redirect exact from="/" to="login" />
              <Route exact path="/login" component={Login} />
              <PrivateRoute exact adminOnly={true} path="/register-new-user" component={Register} />
              <PrivateRoute exact adminOnly={true} path="/show-all-users" component={Userlist} />
              <PrivateRoute exact adminOnly={true} path="/edit-user/:userId" component={EditUser} />
              <PrivateRoute exact adminOnly={true} path="/upload-document" component={UploadDocument} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute
                exact
                path="/salesdocuments/:categoryId"
                component={SalesDocuments}
              />
              <PrivateRoute exact path="/send" component={SendSalesDocuments} />
              <PrivateRoute exact path="/form1" component={Form1} />
              <PrivateRoute exact path="/links" component={Links} />
              <PrivateRoute exact path="/contact" component={Contact} />
            </Switch>
          </section>
          <br />
          <br />
          <Footer />
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
