export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_UPDATED_SALESDOCUMENTS = "GET_UPDATED_SALESDOCUMENTS";
export const UPDATED_SALESDOCUMENT_ERROR = "UPDATED_SALESDOCUMENT_ERROR";
export const CLEAR_UPDATED_SALESDOCUMENTS = "CLEAR_UPDATED_SALESDOCUMENTS";
export const GET_SALESDOCUMENTS = "GET_SALESDOCUMENTS";
export const SALESDOCUMENT_ERROR = "SALESDOCUMENT_ERROR";
export const CLEAR_SALESDOCUMENTS = "CLEAR_SALESDOCUMENTS";
export const GET_ADDED_SALESDOCUMENTS = "GET_ADDED_SALESDOCUMENTS";
export const ADD_SALESDOCUMENT = "ADD_SALESDOCUMENT";
export const REMOVE_SALESDOCUMENT = "REMOVE_SALESDOCUMENT";
export const DELETE_SALESDOCUMENT = "DELETE_SALESDOCUMENT";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const CLEAR_ADDED_SALESDOCUMENTS = "CLEAR_ADDED_SALESDOCUMENTS";

export const GET_USERLIST = "GET_USERLIST";
export const USERLIST_ERROR = "USERLIST_ERROR";
export const CLEAR_USERLIST = "CLEAR_USERLIST";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const GET_USER = "GET_USER";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const CLEAR_GET_USER =  "CLEAR_GET_USER";
