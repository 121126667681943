import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sendForm1 } from "../../actions/form1";
import Alert from "../../components/layout/Alert";

const now = new Date();

var dateNow =
  now.getDate() + "." + (now.getMonth() + 1) + "." + now.getFullYear();
var timeNow =
  now.getHours() + ":" + (now.getMinutes() < 10 ? "0" : "") + now.getMinutes();

const Form1 = ({ sendForm1, auth: { user }, history }) => {
  const [formData, setFormData] = useState({
    mandate: "SDAG",
    formtype: "Besuchsbericht",
    dateofvisit: dateNow,
    timeofvisit: timeNow,
    salesmanagername: user && user.name,
    sender: user && user.email,
    position: user && user.position,
    telephone: user && user.telephone,
    recipient: user && user.email,
    visittype: "Besuch",
    companyname: "",
    customernumber: "",
    locationnumber: "",
    customercontactperson: "",
    invoiceemail: "",
    comment: ""
  });

  const [displayMoreForms, ToggleMoreForms] = useState(false);

  const {
    // eslint-disable-next-line no-unused-vars
    mandate,
    // eslint-disable-next-line no-unused-vars
    formtype,
    dateofvisit,
    timeofvisit,
    salesmanagername,
    // eslint-disable-next-line no-unused-vars
    sender,
    // eslint-disable-next-line no-unused-vars
    position,
    // eslint-disable-next-line no-unused-vars
    telephone,
    recipient,
    visittype,
    companyname,
    customernumber,
    locationnumber,
    customercontactperson,
    invoiceemail,
    comment
  } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    sendForm1(formData, history);
  };

  return (
    <Fragment>
      <section className="page-section">
        <div className="container">
          <h2 className="page-section-heading text-uppercase text-primary mb-0">
            Formulare
          </h2>
          <br />
          <header className="masthead text-primary" id="formulare-bg"></header>
          <br />
          <p className="lead">Besprechungs- / Anrufnotiz</p>
          <small>* = Pflichtfelder</small>
          <div className="row">
            <form className="form" onSubmit={e => onSubmit(e)}>
              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>E-Mail-Empfänger</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="* Senden an?"
                    name="recipient"
                    value={recipient}
                    onChange={e => onChange(e)}
                  />
                  <small className="form-text-primary">
                    <b>* E-Mail-Empfänger (nur einer möglich!)</b>, an welche
                    E-Mail-Adresse soll die Besprechungs- / Anrufnotiz gesendet
                    werden (Versender bekommen immer alles CC)
                  </small>
                </div>
              </div>
              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>
                    Datum des Besuchs / Anrufes / Zeitpunkt des Besuchs /
                    Anrufes
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Besuchsdatum / Datum des Anrufes"
                    name="dateofvisit"
                    value={dateofvisit}
                    onChange={e => onChange(e)}
                  />
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Besuchszeitpunkt / Zeitpunkt des Anrufes"
                    name="timeofvisit"
                    value={timeofvisit}
                    onChange={e => onChange(e)}
                  />
                  <small className="form-text">
                    Datum des Besuchs / Anrufes zB ({dateofvisit}) / Zeitpunkt
                    des Besuchs / Anrufes zB ({timeofvisit})
                  </small>
                </div>
              </div>
              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Betreuername</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="* Betreuername"
                    name="salesmanagername"
                    value={salesmanagername}
                    onChange={e => onChange(e)}
                  />
                  <small className="form-text">* Betreuername</small>
                </div>
              </div>

              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Art des Termins</label>
                  <select
                    name="visittype"
                    value={visittype}
                    onChange={e => onChange(e)}
                    className="form-control"
                  >
                    <option value="0" className="form-control">
                      * Art des Termines (Besuch / Anruf)
                    </option>
                    <option value="Besuch" className="form-control">
                      Besuch
                    </option>
                    <option value="Anruf" className="form-control">
                      Anruf
                    </option>
                  </select>
                  <small className="form-text">
                    * Art des Termines (Besuch / Anruf)
                  </small>
                </div>
              </div>

              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Firmename</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="* Firmenname"
                    name="companyname"
                    value={companyname}
                    onChange={e => onChange(e)}
                  />
                  <small className="form-text">* Firmenname</small>
                </div>
              </div>

              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Kundennr.</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Kundennr."
                    name="customernumber"
                    value={customernumber}
                    onChange={e => onChange(e)}
                  />
                  <small className="form-text">Kundennr.</small>
                </div>
              </div>

              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Standplatznr.</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Standplatznr."
                    name="locationnumber"
                    value={locationnumber}
                    onChange={e => onChange(e)}
                  />
                  <small className="form-text">Standplatznr.</small>
                </div>
              </div>

              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Ansprechperson bei Firma</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Ansprechperson"
                    name="customercontactperson"
                    value={customercontactperson}
                    onChange={e => onChange(e)}
                  />
                  <small className="form-text">Ansprechperson bei Firma</small>
                </div>
              </div>

              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>E-Mail für Rechnung?</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Rechnungs-E-Mail-Adresse?"
                    name="invoiceemail"
                    value={invoiceemail}
                    onChange={e => onChange(e)}
                  />
                  <small className="form-text">
                    E-Mail für Rechnung? (Eintragen wenn JA)
                  </small>
                </div>
              </div>

              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Name</label>
                  <textarea
                    className="form-control form-textarea"
                    name="comment"
                    value={comment}
                    onChange={e => onChange(e)}
                  >
                    * Grund des Besuchs, Aufgaben:
                  </textarea>
                  <small className="form-text">
                    * Grund des Besuchs, Aufgaben aus dem Termin/Telefonat
                  </small>
                </div>
              </div>
              <br />
              <Alert />
              <br />

              <input type="submit" className="btn btn-primary btn-xl" />
              <br />
              <br />
              <p>
                <a href="#!" onClick={() => ToggleMoreForms(!displayMoreForms)}>
                  Wo sind die weiteren Formulare?
                </a>
              </p>
              {displayMoreForms && (
                <Fragment>
                  <ul className="listplus">
                    <li className="listplus">
                      Werden weiteren Formulare benötigt -> E-Mail an&nbsp;
                      <a href="mailto:w.erhart@saubermacher.at">
                        w.erhart@saubermacher.at
                      </a>
                    </li>
                  </ul>
                </Fragment>
              )}
            </form>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Form1.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  sendForm1: PropTypes.func.isRequired
});

export default connect(mapStateToProps, { sendForm1 })(withRouter(Form1));
