import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({
                                        text,
                                        header,
                                        message,
                                        action,
                                        title
                                    }) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const onConfirm = () => {
        action();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <span>
           <a href="#!"
              title={title}
              onClick={handleClickOpen}>{text}</a>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <p className="lead">{header}</p>
                </DialogTitle>
                <DialogContent>
                    <p>{message}</p>
                </DialogContent>
                <DialogActions>
                    <button onClick={() => {
                        handleClose();
                        onConfirm();
                    }} className="btn btn-primary btn-sm" id="ok">
                        Ja
                    </button>
                    <button onClick={handleClose} className="btn btn-primary btn-light btn-sm" id="cancel">
                        Nein
                    </button>
                </DialogActions>
            </Dialog>
        </span>
    );
}
