import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUpdatedDocuments } from "../../actions/document";
import Spinner from "../layout/Spinner";
import UpdatedSalesDocuments from "./UpdatedSalesDocuments";
import Categories from "./Categories";

const Dashboard = ({
  getUpdatedDocuments,
  auth: { user },
  document: { updatedDocuments, loading }
}) => {
  useEffect(() => {
    getUpdatedDocuments();
    // eslint-disable-next-line
  }, []);
  return updatedDocuments === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <section className="page-section">
        <div className="container">
          <br />
          <p className="lead">
            Herzlich willkommen, {user && user.name.replace(/ .*/, "")}!
          </p>

          <Fragment>
            <header id="updatedSalesDocuments-bg">
              <div className="container d-flex flex-column text-primary">
                <UpdatedSalesDocuments newdocuments={updatedDocuments} />
              </div>
            </header>
            <br />
            <hr />
            <br />
            <Categories />
            <br />
          </Fragment>
        </div>
      </section>
    </Fragment>
  );
};

Dashboard.propTypes = {
  getUpdatedDocuments: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  document: state.document
});

export default connect(mapStateToProps, { getUpdatedDocuments })(Dashboard);
