import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSalesDocuments } from "../../actions/document";
import Spinner from "../layout/Spinner";
import SalesDocumentsC from "./SalesDocumentsC";
import { getCategoryName } from "../../actions/category";
import Alert from "../../components/layout/Alert";

const SalesDocuments = ({
  getSalesDocuments,
  auth: { user },
  document: { salesDocuments, loading },
  match,
  location
}) => {
  useEffect(() => {
    getSalesDocuments(match.params.categoryId);
    // eslint-disable-next-line
  }, []);
  return salesDocuments === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <section className="page-section">
        <div className="container">
          <h2 className="page-section-heading text-uppercase text-primary mb-0">
            Unterlagen
          </h2>
          <br />
          <header className="masthead text-primary" id="unterlagen-bg"></header>
          <br />
          <Link to="/dashboard">
            <i className="fas fa-arrow-left"></i> zurück zur Übersicht
          </Link>
          <br />
          <br />
          <p className="lead">{getCategoryName(match.params.categoryId)}</p>
          <Alert />
          <Fragment>
            <SalesDocumentsC salesdocuments={salesDocuments} />
          </Fragment>
          <br />
          <Link to="/dashboard">
            <i className="fas fa-arrow-left"></i> zurück zur Übersicht
          </Link>
        </div>
      </section>
    </Fragment>
  );
};

SalesDocuments.propTypes = {
  getSalesDocuments: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  document: state.document
});

export default connect(mapStateToProps, { getSalesDocuments })(SalesDocuments);
