import React, { Fragment } from "react";

const Contact = props => {
  return (
    <Fragment>
      <section className="page-section">
        <div className="container">
          <h2 className="page-section-heading text-uppercase text-primary mb-0">
            Kontakt
          </h2>
          <br />
          <header className="masthead text-primary" id="contact-bg"></header>
          <br />
          <p className="lead">Übersicht der zentralen Kontaktdaten</p>
          <b>Saubermacher Dienstleistungs AG</b>
          <br />
          Hans-Roth-Straße 1<br />
          8073 Feldkirchen bei Graz
          <br />
          <br />
          T +43 (0) 59 800
          <br />
          F +43 (0) 59 800-1099
          <br />E{" "}
          <a href="mailto:office@saubermacher.at">office@saubermacher.at</a>
          <br />
          <br />
          <a
            href="https://www.saubermacher.at/de/feldkirchen_bei_graz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Infos zu allen Standorten
          </a>
          <br />
          Rechtsform: Aktiengesellschaft
          <br />
          Firmensitz: Feldkirchen bei Graz
          <br />
          Firmenbuchnummer: 46653h
          <br />
          Firmenbuchgericht: Landesgericht für ZRS Graz
          <br />
          UID: ATU 28658002
          <br />
          DVR: 0810266
          <br />
          <br />
          <b>Sales Portal Support</b>
          <br />
          Dipl.-Ing. Willibald Erhart, MBA MSc
          <br />
          Hans-Roth-Straße 1, 8073 Feldkirchen
          <br />
          Telefon: +43 (0) 59 800 1510
          <br />
          E-Mail:{" "}
          <a href="mailto:w.erhart@saubermacher.at">w.erhart@saubermacher.at</a>
        </div>
      </section>
    </Fragment>
  );
};

export default Contact;
