import React, {Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import CheckPermissions from '../auth/CheckPermissions';
import {Link} from "react-router-dom";
import Spinner from "../layout/Spinner";
import UserlistC from "./UserlistC";
import {getUserlist} from "../../actions/users";

const Userlist = ({
                      getUserlist,
                      auth: {user},
                      users: {userlist}
                  }) => {

    useEffect(() => {
        getUserlist();
    }, [getUserlist]);

    return userlist === null ? (
        <Spinner/>
    ) : (
        <div>
            <CheckPermissions>
                <Fragment>
                    <section className="page-section">
                        <div className="container">
                            <h2 className="page-section-heading text-uppercase text-primary mb-0">
                                Benutzer
                            </h2>
                            <br/>
                            <header className="masthead text-primary" id="unterlagen-bg"></header>
                            <br/>
                            <Link to="/dashboard">
                                <i className="fas fa-arrow-left"></i> zurück zur Übersicht
                            </Link>
                            <br/>
                            <br/>
                            <p className="lead">Liste der BenutzerInnen</p>
                            <Fragment>

                                <UserlistC userlist={userlist} />

                            </Fragment>
                            <br/>
                            <Link to="/dashboard">
                                <i className="fas fa-arrow-left"></i> zurück zur Übersicht
                            </Link>
                        </div>
                    </section>
                </Fragment>
            </CheckPermissions>
        </div>
    );
};

Userlist.propTypes = {
    getUserlist: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    users: state.users
});

export default connect(mapStateToProps, {getUserlist})(
    Userlist
);
