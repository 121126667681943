import axios from "axios";
import {setAlert} from "./alert";
import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_UPDATED_SALESDOCUMENTS,
    CLEAR_SALESDOCUMENTS,
    CLEAR_ADDED_SALESDOCUMENTS,
    CLEAR_USERLIST,
    CLEAR_GET_USER
} from "./types";
import setAuthToken from "../utils/setAuthToken";

// Load User
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    } else {
        return dispatch({
            type: AUTH_ERROR
        });
    }

    try {
        const res = await axios.get("/api/auth");

        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: AUTH_ERROR
        });
    }
};

// Register User
export const register = ({
                             name,
                             position,
                             telephone,
                             email,
                             password,
                             sendLoginAsEMail
                         }) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    const body = JSON.stringify({
        name,
        position,
        telephone,
        email,
        password,
        sendLoginAsEMail
    });

    try {
        await axios.post("/api/users", body, config);
        dispatch(
            setAlert("Benutzer erfolgreich registriert", "success"),
        );
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
    }
};

// Edit existing user
export const editUser = ({
                             user,
                             userId,
                             name,
                             position,
                             telephone,
                             email,
                             password,
                             sendLoginAsEMail
                         }) => async dispatch => {


    if (user.name === name && user.position === position && user.telephone === telephone && password === "" && sendLoginAsEMail === "no") {
        dispatch(
            setAlert("Es wurden keine Daten geändert.", "danger"),
        );
    } else {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        const body = JSON.stringify({
            userId,
            name,
            position,
            telephone,
            email,
            password,
            sendLoginAsEMail
        });

        try {
            await axios.put("/api/users", body, config);

            dispatch(
                setAlert("Benutzerdaten erfolgreich geändert", "success"),
            );
        } catch (err) {
            dispatch(
                setAlert("Benutzerdaten konnten nicht geändert werden.", "danger"),
            );

            const errors = err.response.data.errors;
            if (errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
            }
        }
    }
};


// Login User
export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    const body = JSON.stringify({email, password});

    try {
        const res = await axios.post("/api/auth", body, config);
        setAlert("Login erfolgreich", "success");
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: LOGIN_FAIL
        });
    }
};


// Logout & clear documents
export const logout = () => dispatch => {
    dispatch({type: CLEAR_UPDATED_SALESDOCUMENTS});
    dispatch({type: CLEAR_SALESDOCUMENTS});
    dispatch({type: CLEAR_ADDED_SALESDOCUMENTS});
    dispatch({type: CLEAR_USERLIST});
    dispatch({type: CLEAR_GET_USER});
    dispatch({type: LOGOUT});
};
