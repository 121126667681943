import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_UPDATED_SALESDOCUMENTS,
  UPDATED_SALESDOCUMENT_ERROR,
  GET_SALESDOCUMENTS,
  SALESDOCUMENT_ERROR,
  ADD_SALESDOCUMENT,
  REMOVE_SALESDOCUMENT,
  DELETE_SALESDOCUMENT,
  UPLOAD_DOCUMENT,
} from "./types";

// Get last updated documents
export const getUpdatedDocuments = () => async dispatch => {
  try {
    const res = await axios.get("api/documents/lastupdated");

    dispatch({
      type: GET_UPDATED_SALESDOCUMENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATED_SALESDOCUMENT_ERROR,
      payload: { msg: err, status: err }
    });
  }
};

// Get sales documents by category
export const getSalesDocuments = categoryId => async dispatch => {
  try {
    const res = await axios.get("/api/documents/" + categoryId);

    dispatch({
      type: GET_SALESDOCUMENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SALESDOCUMENT_ERROR,
      payload: { msg: err, status: err }
    });
  }
};

// Add sales document to store
export const addSalesDocument = (
  salesDocumentId,
  salesDocumentName,
  salesDocumentUrl
) => async dispatch => {
  try {
    var salesDocument =
      '{ "_id":"' +
      salesDocumentId +
      '" , "name":"' +
      salesDocumentName +
      '", "url":"' +
      salesDocumentUrl +
      '" }';

    dispatch({
      type: ADD_SALESDOCUMENT,
      payload: JSON.parse(salesDocument)
    });

    dispatch(
      setAlert(
        "Vertriebsunterlage: " + salesDocumentName + " hinzugefügt",
        "success"
      )
    );
  } catch (err) {
    dispatch({
      type: SALESDOCUMENT_ERROR,
      payload: { msg: err }
    });
    dispatch(
      setAlert("Fehler beim Hinzufügen der Vertriebsunterlage", "danger")
    );
  }
};

// Remove sales document in store
export const removeSalesDocument = (
  salesDocumentId,
  salesDocumentName
) => async dispatch => {
  try {
    dispatch({
      type: REMOVE_SALESDOCUMENT,
      payload: salesDocumentId
    });

    dispatch(
      setAlert(
        "Vertriebsunterlage: " + salesDocumentName + " entfernt",
        "success"
      )
    );
  } catch (err) {
    dispatch({
      type: SALESDOCUMENT_ERROR,
      payload: { msg: err }
    });
    dispatch(
      setAlert("Fehler beim Entfernen der Vertriebsunterlage", "danger")
    );
  }
};

// Delete sales document
export const deleteSalesDocument = (
    salesDocumentId,
    salesDocumentName
) => async dispatch => {
  try {
    const res = await axios.delete("/api/documents/" + salesDocumentId);

    dispatch({
      type: DELETE_SALESDOCUMENT,
      payload: salesDocumentId
    });

    dispatch(
        setAlert(
            "Vertriebsunterlage: " + salesDocumentName + " löschen: " + res.data.msg,
            "success"
        )
    );
  } catch (err) {
    dispatch({
      type: SALESDOCUMENT_ERROR,
      payload: { msg: err }
    });
    dispatch(
        setAlert("Fehler beim Entfernen der Vertriebsunterlage", "danger")
    );
  }
};

//Upload new sales document
export const uploadDocument = (
   formData
) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    let multiFormData = convertToMultiPartFormData(formData);

    const res = await axios.post("/api/documents", multiFormData, config);

    dispatch({
      type: UPLOAD_DOCUMENT,
      payload: res.data._id
    });

    dispatch(
        setAlert(
            "Vertriebsunterlage: " + formData.name + " wurde hochgeladen",
            "success"
        )
    );
  } catch (err) {
    dispatch({
      type: SALESDOCUMENT_ERROR,
      payload: { msg: err }
    });
    dispatch(
        setAlert("Fehler beim Hochladen der Vertriebsunterlage", "danger")
    );
  }
}

function convertToMultiPartFormData(formData){
  let multiFormData = new FormData();
  Object.keys(formData).forEach(key => multiFormData.append(key, formData[key]));
  return multiFormData;
}

// Send form sales documents (Dokumentenversand)
export const sendForm2 = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    await axios.post("/api/documents/send", formData, config);

    dispatch(
      setAlert("Dokumente versendet an: " + formData.recipient, "success")
    );

    history.push("/send");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
