import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { removeSalesDocument } from "../../actions/document";

const AddedSalesDocuments = ({ salesdocuments, removeSalesDocument }) => {
  var dataAvailable = false;

  if (salesdocuments !== null) {
    if (salesdocuments.length !== 0) {
      dataAvailable = true;
    }
  }

  if (dataAvailable) {
    const documents = salesdocuments.map(doc => (
      <li key={doc._id} className="listplus">
        {doc.name}&nbsp;
        <a
          href={doc.url}
          target="_blank"
          title={doc.name + " anzeigen"}
          rel="noopener noreferrer"
          key={doc._id}
        >
          Anzeigen
        </a>
        &nbsp;|&nbsp;
        <a
          href="#!"
          title={doc.name + " von der Versandauswahl entfernen"}
          onClick={e => {
            removeSalesDocument(doc._id, doc.name);
          }}
        >
          Entfernen
        </a>
      </li>
    ));
    return (
      <Fragment>
        <p className="lead">Unterlagen zum Versand</p>
        <ul className="listplus">{documents}</ul>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <p className="lead">Unterlagen zum Versand</p>
        <div className="alert alert-warning">
          Hinweis: Aktuell noch keine Unterlagen zum Versand hinzugefügt
        </div>
      </Fragment>
    );
  }
};

AddedSalesDocuments.propTypes = {
  salesdocuments: PropTypes.array.isRequired,
  removeSalesDocument: PropTypes.func.isRequired
};

export default connect(null, { removeSalesDocument })(AddedSalesDocuments);
