import React, { Fragment } from "react";

const Links = props => {
  return (
    <Fragment>
      <section className="page-section">
        <div className="container">
          <h2 className="page-section-heading text-uppercase text-primary mb-0">
            Links
          </h2>
          <br />
          <header className="masthead text-primary" id="links-bg"></header>
          <br />
          <p className="lead">Links zu vertriebsunterstützenden Tools</p>
          <p className="lead">DokuTool Portal</p>
          Adresse zum Portal:&nbsp;
          <a
            href="http://dokutool.saubermacher.at/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://dokutool.saubermacher.at/
          </a>
          <br /> <br />
          <p className="lead">Stoffstrom Ticketsystem</p>
          Adresse zum Portal:&nbsp;
          <a
            href="https://saubermacher.service-now.com/nav_to.do?ri=/x_segh4_stoffstrom_material_flow_ticket.do%3Fsys_id%3D-1%26sysparm_stack%3Dx_segh4_stoffstrom_material_flow_ticket_list.do"
            target="_blank"
            rel="noopener noreferrer"
          >
            Neues Ticket anlegen
          </a>
          &nbsp;/&nbsp;
          <a
            href="https://saubermacher.service-now.com/x_segh4_stoffstrom_material_flow_ticket_list.do"
            target="_blank"
            rel="noopener noreferrer"
          >
            Meine Tickets anzeigen
          </a>
          <br /> <br />
          <p className="lead">Kundenportal</p>
          Adresse zum Portal:&nbsp;
          <a
            href="https://kundenportal.saubermacher.at"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://kundenportal.saubermacher.at/
          </a>
          <br />
          <br />
          Demo Zugangsdaten:
          <ul className="listplus">
            <li className="listplus">KMU: Benutzer: testkmu / Passwort: a</li>
            <li className="listplus">
              KMU: Benutzer: testkmup / Passwort: erhart (mit Rechnungen /
              Lieferscheinen)
            </li>
            <li className="listplus">
              Industrie: Benutzer: testindustrie / Passwort: a
            </li>
            <li className="listplus">
              Gemeinde: Benutzer: testgemeinde / Passwort: a
            </li>
          </ul>
          <br />
          <p className="lead">CRM</p>
          Adresse zum Portal:&nbsp;
          <a
            href="https://my328309.crm.ondemand.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://my328309.crm.ondemand.com/
          </a>
        </div>
      </section>
    </Fragment>
  );
};

export default Links;
